import { tns } from 'tiny-slider/src/tiny-slider';

const BREED_SIZES = [
  {
    name: 'dwarf',
    adult_when_weeks: 35
  },
  {
    name: 'small',
    adult_when_weeks: 43
  },
  {
    name: 'medium',
    adult_when_weeks: 52
  },
  {
    name: 'large',
    adult_when_weeks: 78
  },
  {
    name: 'giant',
    adult_when_weeks: 100
  }
];

const IS_PUPPY_VALIDATION = PET.dog_stage === 'puppy' && STEP === 'weight';

var helperFunctions = (function () {
  return {
    removeActiveClass: function (elements, className) {
      Array.prototype.forEach.call(elements, function (el) {
        el.classList.remove(className);
      })
    },
    getCheckedItems: function (elements) {
      return Array.prototype.filter.call(elements, el => el.checked);
    },
    getSelectedValues: function (select) {
      const selected = [...select.options].filter(option => option.selected);
      const values = selected.map(option => option.value);

      return values;
    },
    showLoader: function () {
      const loader = document.getElementById('loading');
      if (!loader) return;

      loader.classList.add('show');
    },
    showSpinner: function () {
      const btnNextIcon = document.querySelector('.btn-hb-submit-icon');
      if (!btnNextIcon) return;
      btnNextIcon.classList.add('btn-hb-submit-loader');
    },
    setCookie: function (name, value, domain) {
      try {
        document.cookie = `${name}=${value}; SameSite=None; Secure; Domain=${domain}`;
      } catch (error) {
        console.error(`Failed to set cookie: ${error}`);
      }
    },
    enforceNumericInput: function (inputElement) {
      inputElement.addEventListener('keydown', function (e) {
        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
          (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
          (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
          (e.keyCode === 86 && (e.ctrlKey === true || e.metaKey === true)) ||
          (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
          (e.keyCode >= 35 && e.keyCode <= 40)) {
          return;
        }
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
        }
      });
    }
  }
})();

var petConfFunctions = function (helpers) {

  var viewportHeight = window.innerHeight;
  var viewportWidth = window.innerWidth;

  /** forms */
  function handleRadioSelect() {
    var radioSelect = document.querySelector('.radio-slider');
    if (!radioSelect) return;

    var activeRadioItem = radioSelect.querySelector('input[checked]');
    if (activeRadioItem) activeRadioItem.parentNode.classList.add('active');

    var radioItems = radioSelect.querySelectorAll('.radio-item');

    let slider = document.querySelector(".radio-slider").querySelectorAll("img")
    const radioItemsArray = Array.prototype.slice.call(radioItems)
    let selectedRadio = Array.prototype.filter.call(radioItems, function (item) {
      return item.classList.contains("active")
    })

    let selected = radioItemsArray.indexOf(selectedRadio[0])
    let touchstartX = 0
    let touchendX = 0

    function handleGesture() {
      if (touchendX < touchstartX && selected < radioItemsArray.length - 1) selected = selected + 1

      if (touchendX > touchstartX && selected > 0) selected = selected - 1

      radioItems[selected].checked = true;
      radioItems[selected].click();
    }

    Array.prototype.forEach.call(slider, function (slide) {
      slide.addEventListener('touchstart', e => {
        touchstartX = e.changedTouches[0].screenX
      })
      slide.addEventListener('touchend', e => {
        touchendX = e.changedTouches[0].screenX
        handleGesture()
      })
    })

    Array.prototype.forEach.call(radioItems, function (radioItem) {
      radioItem.addEventListener('click', function (e) {
        selected = radioItemsArray.indexOf(radioItem)
        e.stopPropagation();
        helpers.removeActiveClass(radioItems, 'active');
        radioItem.classList.add('active');
        if (/IMG/.test(e.target.nodeName)) {
          var inputEl = e.target.parentNode.querySelector('input[type="radio"]');

          inputEl.checked = true;
          inputEl.click();
        } else if (/radio-item/.test(e.target.classList)) {
          var inputEl = e.target.querySelector('input[type="radio"]');

          inputEl.checked = true;
          inputEl.click();
        }
      });
    });
  };

  function handleMultiCheckSelect() {
    var multiCheck = document.querySelector('.multi-check');
    if (!multiCheck) return;

    var multiItems = multiCheck.querySelectorAll('.multi-check-group');
    Array.prototype.forEach.call(multiItems, function (multiItem) {
      multiItem.addEventListener('click', function (e) {
        e.stopPropagation();
        var checkbox = multiItem.querySelector('input');
        multiItem.classList.toggle('checked', checkbox.checked);
      });
    })
  }

  /** info box */
  function handleInfoBoxDisplay() {
    var infoBoxContainer = document.querySelector('.info');
    if (!infoBoxContainer) return;

    var infoBox = infoBoxContainer.querySelector('.info-box');
    if (!infoBox) return;

    var infoBoxPerson = infoBoxContainer.querySelector('.info-person');
    var infoBoxLink = infoBoxContainer.querySelector('a');

    infoBoxLink.addEventListener('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
      infoBox.classList.toggle('d-none');
      infoBoxPerson.classList.toggle('d-none');
    })
  }

  function handleInfoBoxPosition() {
    var infoBoxContainer = document.querySelector('.info');
    if (!infoBoxContainer) return;

    var mainHeight = document.querySelector('main').clientHeight + 90; // footer height

    if (/fixed/.test(infoBoxContainer.classList)) {
      mainHeight += infoBoxContainer.clientHeight + 80; // 80 = margin top (45) + avatar absolute (35)
    }

    if (viewportWidth > 767) {
      mainHeight += 90;
    }

    infoBoxContainer.classList.toggle('info-fixed', mainHeight < viewportHeight);
  }

  function handleSelect() {
    var selects = document.querySelectorAll('select');
    if (!selects) return;

    Array.prototype.forEach.call(selects, function (select) {
      var select = document.getElementById(select.id)
      const selectr = new Selectr(select, {
        disabledMobile: true,
        defaultSelected: false,
        placeholder: select.dataset.placeholder || '',
        multiple: select.dataset.multiple ? select.dataset.multiple : false,
        maxSelections: select.dataset.maxSelections ? parseInt(select.dataset.maxSelections) : 1,
        messages: {
          noResults: select.dataset.noResults || '',
          searchPlaceholder: select.dataset.searchPlaceholder || '',
          maxSelections: select.dataset.maxSelectionsMessage || ''
        }
      });

      if (select.id === 'breed') {
        mixedBreedSelection(select, selectr);
      }
    });
  }

  function handleHealthLabels() {
    var form = document.querySelector('form');
    if (!form) return;

    var inputs = form.querySelectorAll('[name="pet[health_issues][]"]');
    if (inputs.length === 0) return;

    var buttonLabel = form.querySelector('button[type="submit"] span');

    Array.prototype.forEach.call(inputs, function (input) {
      input.addEventListener('change', function () {
        var isChecked = form.querySelectorAll('[name="pet[health_issues][]"]:checked').length > 0;
        buttonLabel.textContent = isChecked ? I18n.next : I18n.healthy;
      })
    })
  }

  function handleAllergyLabels() {
    var form = document.querySelector('form');
    if (!form) return;

    var inputs = form.querySelectorAll('[name="pet[allergies][]"]');
    if (inputs.length === 0) return;

    var buttonLabel = form.querySelector('button[type="submit"] span');

    Array.prototype.forEach.call(inputs, function (input) {
      input.addEventListener('change', function () {
        var isChecked = form.querySelectorAll('[name="pet[allergies][]"]:checked').length > 0;
        buttonLabel.textContent = isChecked ? I18n.next : I18n.no_allergies;
      })
    })
  }

  function breedNotice() {
    const form = document.querySelector('form');
    if (!form) return;

    const select = form.querySelector('select[name="pet[breed][]"]');
    if (!select) return;

    const selectrContainer = document.querySelector('.selectr-container');
    const selectrNotice = document.querySelector('.selectr-notice');

    const breedCheckbox = form.querySelector('[name="breed-mixed-checkbox"]');

    showBreedNotice(select, selectrContainer, selectrNotice);

    select.addEventListener('change', function () {
      breedCheckbox.closest('.choose').classList.toggle('d-none', select.selectedOptions.length >= 3);
      const selectedValues = helpers.getSelectedValues(select);
      breedCheckbox.checked = selectedValues.includes(breedCheckbox.value);

      showBreedNotice(select, selectrContainer, selectrNotice)
    });
  }

  function showBreedNotice(select, selectrContainer, selectrNotice) {

    if (select.selectedOptions.length == 1 && select.selectedOptions[0]?.label == "Mischling") {
      document.getElementsByClassName("selectr-notice-max")[0]?.remove()
      selectrContainer.classList.add('notice', 'notice-max');

      var noticeMax = selectrContainer.querySelector('.selectr-notice-max');

      if (!noticeMax) {
        var maxText = '' +
          '<div class="selectr-notice-max">' +
          I18n.max_selections_mischling +
          '</div>';
        selectrNotice.insertAdjacentHTML('afterend', maxText);
      }
    } else if (select.selectedOptions.length >= 3) {
      document.getElementsByClassName("selectr-notice-max")[0]?.remove()
      selectrContainer.classList.add('notice', 'notice-max');

      var noticeMax = selectrContainer.querySelector('.selectr-notice-max');

      if (!noticeMax) {
        var maxText = '' +
          '<div class="selectr-notice-max">' +
          I18n.max_selections.replace('{max}', '3') +
          '</div>';
        selectrNotice.insertAdjacentHTML('afterend', maxText);
      }
    }
    else {
      document.getElementsByClassName("selectr-notice-max")[0]?.remove()
      selectrContainer.classList.remove('notice-max');
    }
  }

  function handlePuppyCheckbox() {
    const puppyCheckboxEl = document.querySelector('#choose-puppy');
    if (!puppyCheckboxEl) return;
    const birtYearEl = document.querySelector('#pet_birth_month');
    if (!birtYearEl) return;

    const birthYear = petProfileSessionData['birth_year'];
    const birthMonth = petProfileSessionData['birth_month'];

    if (birthMonth) {
      const birthMonthEl = document.querySelector('#pet_birth_month');
      showPuppyCheckbox(puppyCheckboxEl, birthYear, birthMonthEl.value);
    }

    birtYearEl.addEventListener('change', function (e) {
      showPuppyCheckbox(puppyCheckboxEl, birthYear, e.target.value);
    });
  }

  function showPuppyCheckbox(puppyCheckboxEl, year, month = 11) {
    const selectedDate = new Date(year, month - 1);
    const currentDate = new Date();

    const dateDiffMS = currentDate - selectedDate;
    const puppyMS = 8467200000;

    let adultMS = 60480000000;
    if (PET.breed_size) {
      const adultWeeks = BREED_SIZES.filter(breedSize => breedSize.name === PET.breed_size);
      if (adultWeeks) adultMS = adultWeeks[0].adult_when_weeks * 7 * 24 * 60 * 60 * 1000;
    }

    const shouldShow = adultMS > dateDiffMS && dateDiffMS > puppyMS;
    const isNewborn = dateDiffMS < puppyMS;
    puppyCheckboxEl.classList.toggle('d-none', !shouldShow);
    handlePuppyValue(shouldShow, isNewborn);
  }

  function handlePuppyValue(isCheckedPuppy, isNewborn) {
    const dogStageInput = document.querySelector('#pet_dog_stage');
    const dogStageHiddenInput = document.querySelector('input[name="pet[dog_stage]"]');
    if (!dogStageInput && !dogStageHiddenInput) return;

    dogStageInput.checked = isCheckedPuppy;
    dogStageHiddenInput.value = isNewborn ? "newborn" : "adult";
  }

  function showGenderWhenName() {
    var input = document.querySelector('input[name="pet[name]"]');
    if (!input) return;

    var gender = document.querySelector('.gender-radio');
    if (!gender) return;

    var dividerLine = document.querySelector('.divider-line-selector');
    if (!dividerLine) return;

    var petName = document.querySelector('.pet-name');
    if (!petName) return;

    input.addEventListener('input', function (e) {
      petName.textContent = e.target.value;
      dividerLine.classList.toggle('divider-line', e.target.value !== '')
      gender.classList.toggle('gender-visible', e.target.value !== '')
    });
  }

  function handleMonthLabel() {
    const form = document.querySelector('form');
    if (!form) return;

    const select = form.querySelector('[name="pet[birth_month]"]');
    if (!select) return;

    const buttonLabel = form.querySelector('button[type="submit"] span');

    select.addEventListener('change', function (e) {
      buttonLabel.textContent = e.target.value !== '' ? I18n.next : I18n.skip;
    });
  }

  function handleCurrentFoodLabel() {
    const form = document.querySelector('form');
    if (!form) return;

    const inputElements = form.querySelectorAll('[name="pet[current_food][]"]');
    if (!inputElements.length) return;

    const buttonLabel = form.querySelector('button[type="submit"] span');

    inputElements.forEach(inputEl => {
      inputEl.addEventListener('change', function (e) {
        const selectedItems = helpers.getCheckedItems(inputElements);
        buttonLabel.textContent = selectedItems.length ? I18n.next : I18n.skip;
      });
    });
  }

  function mixedBreedSelection(select, selectr) {

    const form = document.querySelector('form');
    if (!form) return;

    const breedCheckbox = form.querySelector('[name="breed-mixed-checkbox"]');
    if (!select || !breedCheckbox) return;

    select.addEventListener("change", function () {
      if (selectr.getValue(true, true) != undefined && !selectr?.getValue(true, true)?.toString()?.includes("Mischling")) {
        breedCheckbox.parentNode.style.display = "none"
      } else if (
        selectr.getValue(true, true) != undefined && selectr.getValue(true).values.length > 1
      ) {
        breedCheckbox.parentNode.style.display = "none"
      } else {
        breedCheckbox.parentNode.style.display = "block"
        selectr.close()
      }
    })

    var options = select.querySelectorAll('option');
    var mixedOption = Array.prototype.filter.call(options, function (opt) {
      return opt.textContent === 'Mischling';
    });
    var mixedValue = mixedOption.length ? mixedOption[0].value : '';

    breedCheckbox.addEventListener('change', (e) => {
      if (e.target.checked) {
        selectr.clear()
        selectr.setValue(mixedValue);
      } else {
        selectr.setValue(mixedValue);
      }
    })
  }

  function toggleLoaderOnRP() {
    const form = document.querySelector('form');
    if (!form) return;

    const submitBtn = form.querySelector('button[type="submit"]');

    submitBtn.addEventListener('click', function () {
      if (/valid/.test(form.classList)) {
        if (STEP === 'owner') {
          helpers.showLoader();
        } else {
          helpers.showSpinner();
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  function handleOwnerSubmit() {
    const submitBtns = document.querySelectorAll('.owner-submit');
    if (!submitBtns.length) return;

    Array.prototype.forEach.call(submitBtns, function (submitBtn) {
      submitBtn.addEventListener('click', function () {
        const submitFormBtn = document.querySelector('form button[type="submit"]');
        submitFormBtn.click();
      })
    })
  }

  function setupPetFormAutoSubmit() {
    const petForm = document.querySelector("form[id^='edit_pet_']");
    if (!petForm) return;

    const petNameElem = document.querySelector('#pet_name');
    const genderInputs = document.querySelectorAll("input[name='pet[gender]']");
    const petBirthYear = document.querySelector('#pet_birth_year');
    const petBirthMonth = document.querySelector('#pet_birth_month');
    let nameFilled = petNameElem ? petNameElem.value !== '' : false;
    let genderSelected = Array.from(genderInputs).some(input => input.checked);

    function checkButtonAndSubmit(e) {
      if (e.target.dataset.value !== '' || (e.target.id === 'pet_birth_month' && /required/.test(e.target.classList))) return;
      if (e.target.value !== '') {
        showSpinnerAndSubmit();
      }
    }

    function showSpinnerAndSubmit() {
      helpers.showSpinner();
      petForm.submit();
    }

    function checkFormAndSubmit() {
      if (nameFilled && genderSelected) {
        showSpinnerAndSubmit();
      }
    }

    petNameElem && petNameElem.addEventListener('input', () => {
      if (petNameElem.dataset.value !== '') return;
      nameFilled = petNameElem.value !== '';
      checkFormAndSubmit();
    });

    genderInputs.length > 0 && genderInputs.forEach(input => input.addEventListener('change', () => {
      genderSelected = true;
      checkFormAndSubmit();
    }));

    petBirthYear && petBirthYear.addEventListener('change', checkButtonAndSubmit);
    petBirthMonth && petBirthMonth.addEventListener('change', checkButtonAndSubmit);
  }

  var isUnbounceConversionComplete = false;

  function updateUnbounceTracking() {
    if (!document.referrer || isUnbounceConversionComplete) return;

    var referrerDomain = new URL(document.referrer).hostname;

    if (referrerDomain.includes('hellobello') || referrerDomain.includes('hundefutter-ratgeber')) {
      var _ubad = referrerDomain;
      var _ubaq = window._ubaq || [];
      _ubaq.push(['trackGoal']);

      var ub_script = document.createElement('script');
      ub_script.type = 'text/javascript';
      ub_script.src = '//' + _ubad + '/_ub/static/ets/t.js';

      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ub_script, s);
    }
    isUnbounceConversionComplete = true;
  }

  function handleWeightInputValidation() {
    const weightInput = document.querySelector('input[type="number"]');
    const ua = navigator.userAgent.toLowerCase();
    const isSafari = /safari/.test(ua) && !/chrome/.test(ua) && !/android/.test(ua);

    if (!isSafari) {
      return;
    }

    if (weightInput) {
      helpers.enforceNumericInput(weightInput);
    }
  }

  return {
    init: function () {
      handleRadioSelect();
      handleMultiCheckSelect();
      handleInfoBoxDisplay();
      handleInfoBoxPosition();
      handleSelect();
      handleHealthLabels();
      handleAllergyLabels();
      handleMonthLabel();
      handleCurrentFoodLabel();
      breedNotice();
      showGenderWhenName();
      handlePuppyCheckbox();
      toggleLoaderOnRP();
      handleOwnerSubmit();
      setupPetFormAutoSubmit();
      updateUnbounceTracking();
      handleWeightInputValidation();
    },
    resize: function () {
      handleInfoBoxPosition();
    }
  }
};


var resultPageFunctions = function (helpers) {
  var viewportHeight = window.innerHeight;
  var viewportWidth = window.innerWidth;

  function handleActiveElements() {
    var elements = document.querySelectorAll('.choose, .pensions, .cycles');
    if (elements.length === 0) return;

    Array.prototype.forEach.call(elements, function (element) {
      var inputElements = element.querySelectorAll('input');
      if (inputElements.length === 0) return;

      Array.prototype.forEach.call(inputElements, function (inputEl) {
        setTimeout(() => {
          if (inputEl.checked) {
            var item = inputEl.closest('.item');
            if (!item.classList.contains('active')) item.classList.add('active');

            var pensionNewImage = item.querySelector('.pension-new-image');
            if (pensionNewImage) {
              pensionNewImage.classList.add('active');
            }
          } else {
            var item = inputEl.closest('.item');
            if (item.classList.contains('active')) item.classList.remove('active');

            var pensionNewImage = item.querySelector('.pension-new-image');
            if (pensionNewImage) {
              pensionNewImage.classList.remove('active');
            }
          }
        }, 10);

        inputEl.addEventListener('change', function () {
          handleActiveElement(inputEl, element);
        });
      });
    });
  }

  function handleActiveElement(input, element) {
    var type = input.getAttribute('type');

    if (type === 'checkbox') {
      var labelEl = input.closest('label').querySelector('.choose-state');
      input.closest('.item').classList.toggle('active');
      labelEl.innerText = input.checked ? I18n.chosen : I18n.choose;
    } else {
      var activeElements = element.querySelectorAll('.active');

      helpers.removeActiveClass(activeElements, 'active');
      input.closest('.item').classList.add('active');

      var pensionNewImage = input.closest('.item').querySelector('.pension-new-image');
      if (pensionNewImage) {
        pensionNewImage.classList.add('active');
      }

      var inputElements = element.querySelectorAll('input');
      Array.prototype.forEach.call(inputElements, function (inputEl) {
        var labelEl = inputEl.closest('label').querySelector('.choose-state');


        if (!inputEl.checked) {
          var unselectedImage = inputEl.closest('.item').querySelector('.pension-new-image');
          if (unselectedImage) {
            unselectedImage.classList.remove('active');
          }
        }
      });
    }
  }


  function handleSelection() {
    var recipes = document.querySelector('.recipes');
    if (!recipes) return;

    var inputElements = recipes.querySelectorAll('input');
    if (!inputElements.length) return;

    var otherElements = document.querySelectorAll('.pensions, .cycles, #recommendation-form button[type="submit"]');

    function toggleActiveState(inputEl) {
      var recipeItem = inputEl.closest('.recipe');
      var recipeImg = recipeItem.querySelector('.recipe-img');
      var recipeBtn = recipeItem.querySelector('.recipe-btn');

      if (inputEl.checked) {
        recipeItem.classList.add('active');
        recipeImg.classList.add('active');
        recipeBtn.classList.add('active');
      } else {
        recipeItem.classList.remove('active');
        recipeImg.classList.remove('active');
        recipeBtn.classList.remove('active');
      }

      var activeElements = recipes.querySelectorAll('.recipe.active');
      if (!activeElements.length) {
        otherElements.forEach(function (el) {
          el.classList.add('disabled');
        });
      } else {
        otherElements.forEach(function (el) {
          el.classList.remove('disabled');
        });
      }
    }

    Array.prototype.forEach.call(inputElements, function (inputEl) {
      toggleActiveState(inputEl);
    });

    Array.prototype.forEach.call(inputElements, function (inputEl) {
      inputEl.addEventListener('change', function () {
        toggleActiveState(inputEl);
      });
    });
  }



  function viewMore() {
    const viewMore = document.querySelectorAll('a.more');
    if (!viewMore.length) return;

    Array.prototype.forEach.call(viewMore, function (more) {
      more.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        more.classList.toggle('active');
      })
    });
  }

  function stickyPricingBar() {
    const priceEl = document.querySelector('.pricing-bar');
    if (!priceEl) return;

    const footer = document.querySelector('.footer')

    window.addEventListener('scroll', function () {
      var priceHeight = priceEl.offsetHeight;
      var bodyHeight = document.body.clientHeight;
      var windowScrollTop = window.scrollY;
      var windowHeight = window.innerHeight;
      const footerHeight = footer.offsetHeight;

      if (bodyHeight < windowScrollTop + windowHeight + priceHeight - footerHeight) {
        const currentBottom = (windowScrollTop + windowHeight + footerHeight) - bodyHeight;
        priceEl.style.bottom = currentBottom + 'px'
      } else {
        priceEl.style.bottom = '0'
      }
    });
  }

  function stylingPricingBar() {
    const footer = document.querySelector('.footer')
    const pricingBar = document.querySelector('.pricing-bar')
    const main = document.querySelector('.result-page .main');
    if (!footer || !main || !pricingBar) return;

    const footerHeight = footer.offsetHeight;
    main.style.paddingBottom = footerHeight + 'px';
  }

  function toggleLoaderRP() {
    const form = document.querySelector('form');
    if (!form) return;

    const submitBtn = form.querySelector('button[type="submit"]');

    submitBtn.addEventListener('click', function () {
      helpers.showSpinner();
    });
  }

  function addCheckoutLinkListener() {
    var checkoutLink = document.querySelector('.price-checkout-link');
    if (!checkoutLink) return
    checkoutLink.addEventListener('click', function (event) {
      event.preventDefault();
      document.getElementById('recommendation-form').submit();
    });
  }

  function checkoutLink() {
    var checkoutLink = document.querySelector('.checkout-link');
    if (!checkoutLink) return
    checkoutLink.addEventListener('click', function (event) {
      event.preventDefault();
      document.getElementById('recommendation-form').submit();
    });
  }

  function storeParamsInSession() {
    var urlParams = new URLSearchParams(window.location.search);
    var int_wgsid = urlParams.get('int_wgsid');
    var wgsid = urlParams.get('wgsid');

    if (int_wgsid) {
      sessionStorage.setItem('int_wgsid', int_wgsid);
    }

    if (wgsid) {
      sessionStorage.setItem('wgsid', wgsid);
    }
  }

  const testimonialSlider = () => {
    const slider = tns({
      container: '.testimonial-slider',
      items: 1.2,
      slideBy: 1.2,
      autoplay: false,
      nav: true,
      controls: false,
      mouseDrag: true,
      autoplayButtonOutput: false,
      navPosition: 'bottom',
      center: true,
      loop: false,
      responsive: {
        992: {
          items: 3,
          center: false,
        },
        1290: {
          items: 4,
          nav: false,
        },
      },
    });

    const prevButtonT = document.querySelector('.testimonial-slider-control-left');
    const nextButtonT = document.querySelector('.testimonial-slider-control-right');

    const updateArrowColors = () => {
      const info = slider.getInfo();
      const isAtStart = info.index === 0;
      const isAtEnd = info.index >= info.slideCount - info.items;

      if (isAtStart) {
        prevButtonT.style.backgroundColor = '#7DB3AB';
      } else {
        prevButtonT.style.backgroundColor = '#00756c';
      }

      if (isAtEnd) {
        nextButtonT.style.backgroundColor = '#7DB3AB';
      } else {
        nextButtonT.style.backgroundColor = '#00756c';
      }
    };

    slider.events.on('indexChanged', updateArrowColors);
    slider.events.on('initialized', updateArrowColors);

    prevButtonT.addEventListener('click', () => slider.goTo('prev'));
    nextButtonT.addEventListener('click', () => slider.goTo('next'));
  };

  const recipesSlider = () => {
    const slider = tns({
      container: '.intro-slider-recipe',
      items: 1,
      slideBy: 1,
      autoplay: false,
      nav: true,
      loop: false,
      controls: false,
      mouseDrag: true,
      autoplayButtonOutput: false,
      navPosition: 'bottom',
    });

    const prevButton = document.querySelector('.intro-slider-controls-left');
    const nextButton = document.querySelector('.intro-slider-controls-right');

    prevButton.addEventListener('click', () => slider.goTo('prev'));
    nextButton.addEventListener('click', () => slider.goTo('next'));
  };

  return {
    init: function () {
      handleActiveElements();
      handleSelection();
      viewMore();
      stylingPricingBar();
      stickyPricingBar();
      toggleLoaderRP();
      addCheckoutLinkListener();
      checkoutLink();
      storeParamsInSession();
      testimonialSlider();
      recipesSlider();
    },
    resize: function () {
      stylingPricingBar();
    }
  }
};

var generalFunctions = function (helpers) {

  function scrollToTop() {
    var backToTopBtn = document.getElementById('btn-hb-back-to-top');

    if (!backToTopBtn) return;

    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      backToTopBtn.classList.toggle('btn-hb-back-to-top-visible', document.body.scrollTop > 300 || document.documentElement.scrollTop > 300);
      backToTopBtn.classList.toggle('btn-hb-back-to-top-footer', (window.innerHeight + window.pageYOffset + 60) >= document.body.offsetHeight);
      if ((window.innerHeight + window.pageYOffset + 60) >= document.body.offsetHeight) {
        backToTopBtn.style.bottom = 80 - (document.body.offsetHeight - window.innerHeight - window.pageYOffset) + 'px';
      }
    }
    backToTopBtn.addEventListener('click', backToTop);

    function backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  function showNoticeModal() {
    var noticeModalSelector = document.getElementById('notice-modal');
    if (!noticeModalSelector) return;

    var noticeModal = new bootstrap.Modal(noticeModalSelector);
    noticeModal.show();
  }

  return {
    init: function () {
      scrollToTop();
    },
    load: function () {
      showNoticeModal();
    }
  }
};

document.addEventListener("DOMContentLoaded", function () {
  const form = document.querySelector("#recommendation-form");

  const resultPage = document.querySelector(".result-page");
  const recipeSlider = document.querySelector(".intro-slider-c");

  if (form) {
    const sliderImage1 = document.querySelector(".recipe-slider-1");
    const sliderImage2 = document.querySelector(".recipe-slider-2");

    function updateRecipeSlider() {
      const discountPercentageElement = document.querySelector(".discount-percentage");
      const discountValue = discountPercentageElement
        ? discountPercentageElement.textContent.replace(/\D/g, "").trim()
        : "30";

      const selectedRecipes = Array.from(
        form.querySelectorAll('input[name="variant[recipe][]"]:checked')
      ).map((input) => input.value.toLowerCase());

      const selectedKey = selectedRecipes.join("");

      if (selectedKey) {
        sliderImage1.src = `/assets/recommendation/recipes/slider/RecipeSlide1${selectedKey}${discountValue}.png`;
        sliderImage2.src = `/assets/recommendation/recipes/slider/RecipeSlide2${selectedKey}.png`;
      }

      if (resultPage) {
        resultPage.className = `result-page valid ${selectedKey}`;
      }

      if (recipeSlider) {
        recipeSlider.className = `intro-slider-c ${selectedKey}`;
      }
    }

    form.querySelectorAll('input[name="variant[recipe][]"]').forEach((checkbox) => {
      checkbox.addEventListener("change", updateRecipeSlider);
    });

    updateRecipeSlider();
  }

  const introSection = document.querySelector('.pension-wrapper');
  const pricingBarWithInfo = document.querySelector('.pricing-bar-w-info');

  if (introSection && pricingBarWithInfo) {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (!entry.isIntersecting) {
          pricingBarWithInfo.classList.add("visible");
        }
      },
      {
        root: null,
        threshold: 0,
      }
    );

    observer.observe(introSection);
  }

  const recipeSection = document.querySelector('.r-recipes');
  const pricingBarShowMore = document.querySelector('.pricing-bar-extra-show-more');

  if (pricingBarShowMore && recipeSection) {
    pricingBarShowMore.addEventListener('click', () => {
      const sectionPosition = recipeSection.getBoundingClientRect().top + window.scrollY - 30;
      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth',
      });
    });
  }

  const body = document.querySelector("body");

  if (/pet-configurator/.test(body.classList)) petConfFunctions(helperFunctions).init();
  if (/result-page/.test(body.classList)) resultPageFunctions(helperFunctions).init();
  generalFunctions(helperFunctions).init();

  function setupPopup(triggerSelector, popupSelector) {
    const trigger = document.querySelector(triggerSelector);
    const popup = document.querySelector(popupSelector);

    if (trigger && popup) {
      trigger.addEventListener("click", () => {
        popup.classList.toggle("d-none");
      });

      document.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          popup.classList.add("d-none");
        }
      });

      document.addEventListener("click", (event) => {
        if (!popup.contains(event.target) && !trigger.contains(event.target)) {
          popup.classList.add("d-none");
        }
      });
    }
  }


  setupPopup(".trigger-popup-one", ".pension-popup-one");
  setupPopup(".trigger-popup-two", ".pension-popup-two");
  setupPopup(".pension-fridge", ".pension-popup-three");
  setupPopup(".trigger-popup-two-wrapper", ".pension-popup-two-extra");
  setupPopup(".nach-package-info", ".nach-package-info-content");
  setupPopup(".cycle-v-more-info", ".cycle-v-popup");
  setupPopup(".cycle-h-more-info", ".cycle-h-popup");

  const faqButtons = document.querySelectorAll(".btn-faq");
  const faqContentItems = document.querySelectorAll(".content-faq-item");

  faqButtons.forEach((button) => {
    button.addEventListener("click", () => {
      faqButtons.forEach((btn) => btn.classList.remove("active"));
      button.classList.add("active");

      faqContentItems.forEach((item) => item.classList.remove("active"));

      const contentId = button.getAttribute("data-content");
      document.getElementById(contentId).classList.add("active");
    });
  });

  document.querySelectorAll(".pricing-bar-more").forEach((moreButton) => {
    const tooltipText = moreButton.querySelector(".pricing-bar-more-tooltip-text");

    moreButton.addEventListener("click", function (event) {
      event.preventDefault();
      event.stopPropagation();
      tooltipText.classList.toggle("d-block");
    });

    document.addEventListener("click", function (event) {
      if (!moreButton.contains(event.target)) {
        tooltipText.classList.remove("d-block");
      }
    });
  });

  const priceElement = document.querySelector("#discount-data");
  const priceHalfEl = document.querySelector(".pension-price-half");
  const totalPriceElement = document.querySelector(".pension-total-price");
  const totalPriceElementHalf = document.querySelector(".pension-total-price-half");
  const pricingBarTestPrice = document.querySelector(".price-value-test-package");
  const pricingBarTotal = document.querySelector(".pricing-bar-total");

  const formatCurrency = (value) => `€${value.toFixed(2).replace(".", ",")}`;

  const parsePrice = (element) => {
    if (!element || !element.textContent) return 0;
    const num = parseFloat(element.textContent.replace(",", "."));
    return isNaN(num) ? 0 : num;
  };

  const updatePrices = () => {
    let updated = false;

    if (priceElement && totalPriceElement) {
      const pricePerDay = parsePrice(priceElement);
      totalPriceElement.textContent = `Gesamt: ${formatCurrency(pricePerDay * 14)}`;
      updated = true;
    }

    if (priceHalfEl && totalPriceElementHalf) {
      const pricePerHalfDay = parsePrice(priceHalfEl);
      totalPriceElementHalf.textContent = `Gesamt: ${formatCurrency(pricePerHalfDay * 14)}`;
      updated = true;
    }

    if (pricingBarTestPrice && pricingBarTotal) {
      const pricingBarTestPriceValue = parsePrice(pricingBarTestPrice);
      pricingBarTotal.textContent = `Gesamt: ${formatCurrency(pricingBarTestPriceValue * 14)}`;
      updated = true;
    }

    return updated;
  };

  const observer = new MutationObserver(updatePrices);
  [priceElement, pricingBarTestPrice].forEach((el) => {
    if (el) observer.observe(el, { childList: true, subtree: true });
  });

  updatePrices();

  const toggleFaqButton = document.querySelector(".faq-content-three-toggle");
  const faqItems = document.querySelectorAll(".faq-content-three-item");
  let isExpanded = false;

  function initializeFaqItems() {
    faqItems.forEach((item, index) => {
      if (index !== 0 && index !== 1 && index !== faqItems.length - 1) {
        item.classList.add("faq-content-three-hidden");
      } else {
        item.classList.remove("faq-content-three-hidden");
      }
    });
  }

  toggleFaqButton.addEventListener("click", function () {
    if (isExpanded) {
      initializeFaqItems();
      toggleFaqButton.textContent = "Mehr FAQs";
    } else {
      faqItems.forEach((item) => item.classList.remove("faq-content-three-hidden"));
      toggleFaqButton.textContent = "Weniger FAQs";
    }
    isExpanded = !isExpanded;
  });

  initializeFaqItems();
});




window.addEventListener('load', function () {
  generalFunctions(helperFunctions).load();
});

window.addEventListener('resize', function () {
  var body = document.querySelector('body');
  if (/pet-configurator/.test(body.classList)) petConfFunctions(helperFunctions).resize();
  if (/result-page/.test(body.classList)) resultPageFunctions(helperFunctions).resize();
});