import { VariantsHelper } from "../ts/variants_helper";
import { Variant } from "../ts/variant";
import { plainToClass } from 'class-transformer';
import { autobind } from "../ts/autobind";

type GrammageEntry = {
  amount: number,
  weight: string,
  meat: string,
  percentage?: string
};

type Grammage = {
  cowgenuss: GrammageEntry,
  gockelspeis: GrammageEntry,
  ringeltanz: GrammageEntry
};

type GrammageNew = {
  cg: GrammageEntry,
  gs: GrammageEntry,
  rt: GrammageEntry
};

declare global {
  interface Window {
    _tfa?: any;
    I18n: any
  }
}

declare const _tfa: any;
declare const obApi: any;

export class Recommendations {
  variants: any;

  @autobind
  async load_variants(this: Recommendations, initLoading?: boolean) {
    const loading = document.querySelector('#loading') as HTMLInputElement;

    if (!initLoading) loading.classList.add('show');

    let url = document.querySelector('#recommendation-form')!.getAttribute('data-url'),
        endpoint = `${url}`;

    fetch(endpoint).then(response => {
      response.json().then(data => {
        try {
          this.variants = plainToClass(Variant, data);
        } catch(e) {
          console.log('[update_pricing]', e)
        } finally {
          if (!initLoading) loading.classList.remove('show');
        }
      });
    });
  }

  @autobind
  update_slider_images() {
    const recipes = this.current_recipes();

    const sliderImages = document.querySelectorAll('.intro-slider-recipe .intro-slider-recipe-img');
  
    const imageSets: { [key: string]: string[] } = {
      "CG": ['RecipeSlide1cg30.png', 'RecipeSlide2cg.png'],
      "RT": ['RecipeSlide1rt30.png', 'RecipeSlide2rt.png'],
      "GS": ['RecipeSlide1gs30.png', 'RecipeSlide2gs.png'],
      "CGGS": ['RecipeSlide1cggs30.png', 'RecipeSlide2cggs.png'],
      "CGRT": ['RecipeSlide1cgrt30.png', 'RecipeSlide2cgrt.png'],
      "GSRT": ['RecipeSlide1gsrt30.png', 'RecipeSlide2gsrt.png'],
    };
  
    let recipeKey = recipes;

    if (!imageSets[recipeKey]) {
      recipeKey = 'default'; 
    }
  
    const images = imageSets[recipeKey] || ['RecipeSlide1cggsrt30.png', 'RecipeSlide2cggsrt.png'];
  
    sliderImages.forEach((img: HTMLImageElement, index: number) => {
      const imagePath = `/assets/recommendation/recipes/slider/${images[index]}`;
      img.src = imagePath;
      img.alt = `Slider image for ${recipeKey}`;
    });
  }

  @autobind
  async update_variants() {
    let recipes = this.current_recipes();

    if (recipes) {
      this.update_pricing();
      this.update_grammage();
      this.update_slider_images();
    }
    this.update_plan();
    this.update_variant();
    this.update_test_package();
    this.update_pension_view();
  }

  @autobind
  trackAddToCartEvents(): void {
    if (typeof window._tfa === 'undefined' || !_tfa || typeof obApi === 'undefined') return;

    const addToCartButtons = document.querySelectorAll('.btn-add-to-cart');

    addToCartButtons.forEach((button: Element) => {
      button.addEventListener('click', () => {
        _tfa.push({ notify: 'event', name: 'add_to_cart_petcon', id: 1368181 });
        obApi('track', 'Add to cart Petcon');
      });
    });
  }

  @autobind
  add_listeners() {
    document.addEventListener('DOMContentLoaded', function () {
      setTimeout(() => {
        window.HBRecommendations.load_variants(true);
      }, 11);
    })
    
    document.querySelectorAll('input[name^="variant"]').forEach(el => {
      el.addEventListener("change", () => {
        setTimeout(() => {
          window.HBRecommendations.update_variants();
        }, 11);
      })
    })

    this.trackAddToCartEvents();
    this.submit_form();
  }

  @autobind
  update_pricing() {
    let price = this.variants.filter(v =>
      v.option1 === this.current_recipes() && v.option2 === this.current_pension() && v.option3 === this.current_cycle())[0];

    let discountDataElement = document.getElementById("discount-data");
    let discountPercentage = parseFloat(discountDataElement.dataset.discountPercentage);
    let pricePerDayOngoing = price.metafields.filter(f => f.key === 'per_day')[0].value;
    let pricePerDayTestPackageOngoing = price.test_package.filter(f => f.key === 'per_day')[0].value;
    let pricePerDayTestPackage = price.test_package.filter(f => f.key === 'per_day')[0].value;

    let testPackageOngoingPrices: NodeListOf<Element> = document.querySelectorAll('.price-value-test-package-ongoing');
    let testPackagePrices: NodeListOf<Element> = document.querySelectorAll('.price-value-test-package');
    let ongoingPrices: NodeListOf<Element> = document.querySelectorAll('.price-value-ongoing');

    Array.prototype.forEach.call(testPackageOngoingPrices, function (testPackageOngoingPrice: HTMLElement) {
      testPackageOngoingPrice.innerText = pricePerDayTestPackageOngoing.toFixed(2).toString().replace('.', ',');
    })

    Array.prototype.forEach.call(testPackagePrices, function (testPackagePrice: HTMLElement) {
      testPackagePrice.innerText = (pricePerDayTestPackage * discountPercentage).toFixed(2).toString().replace('.', ',');
    })

    Array.prototype.forEach.call(ongoingPrices, function (ongoingPrice: HTMLElement) {
      ongoingPrice.innerText = pricePerDayOngoing.toString().replace('.', ',');
    })
  }

  @autobind
  variant_metafields(variant) : Grammage {
    const grammageEntry: GrammageEntry = {
      amount: -1,
      weight: '',
      meat: ''
    }, grammage: Grammage = {
        cowgenuss: grammageEntry,
        gockelspeis: grammageEntry,
        ringeltanz: grammageEntry
      };

    variant.metafields.filter(meta => meta.namespace === 'sachets').map(field => {
      switch (field.key) {
        case 'cowgenuss':
          grammage.cowgenuss = { 'amount': field.value, weight: '', meat: 'Rind' }; break;
        case 'gockelspeis':
          grammage.gockelspeis = { 'amount': field.value, weight: '', meat: 'Huhn' }; break;
        case 'ringeltanz':
          grammage.ringeltanz = { 'amount': field.value, weight: '', meat: 'Schwein' }; break;
        case 'cowgenuss_weight':
          grammage.cowgenuss.weight = Object.values(JSON.parse(field.value)).join(' '); break;
        case 'gockelspeis_weight':
          grammage.gockelspeis.weight = Object.values(JSON.parse(field.value)).join(' '); break;
        case 'ringeltanz_weight':
          grammage.ringeltanz.weight = Object.values(JSON.parse(field.value)).join(' '); break;
      }
    });

    return grammage;
  }

  @autobind
  variant_metafields_new(variant) : GrammageNew {
    const grammageEntry: GrammageEntry = {
      amount: -1,
      weight: '',
      meat: '',
      percentage: ''
    }, grammage: GrammageNew = {
        cg: grammageEntry,
        gs: grammageEntry,
        rt: grammageEntry
      };

    variant.metafields.filter(meta => meta.namespace === 'sachets' || meta.namespace === 'composition').map(field => {
      switch (field.key) {
        case 'cowgenuss':
          grammage.cg = { 'amount': field.value, weight: '', meat: 'Rind' }; break;
        case 'gockelspeis':
          grammage.gs = { 'amount': field.value, weight: '', meat: 'Huhn' }; break;
        case 'ringeltanz':
          grammage.rt = { 'amount': field.value, weight: '', meat: 'Schwein' }; break;
        case 'cowgenuss_weight':
          grammage.cg.weight = Object.values(JSON.parse(field.value)).join(' ').replace(' g', ''); break;
        case 'gockelspeis_weight':
          grammage.gs.weight = Object.values(JSON.parse(field.value)).join(' ').replace(' g', ''); break;
        case 'ringeltanz_weight':
          grammage.rt.weight = Object.values(JSON.parse(field.value)).join(' ').replace(' g', ''); break;
        case 'cg_percentage':
          grammage.cg.percentage = field.value; break;
        case 'gs_percentage':
          grammage.gs.percentage = field.value; break;
        case 'rt_percentage':
          grammage.rt.percentage = field.value; break;
      }
    });

    return grammage;
  }

  @autobind
  pension_html(grammage: Grammage): string {
    let html = "";

    for(let r in grammage) {
      if(grammage[r].amount > 0) {
        html += `<li class="remove-pension">${grammage[r].amount} x ${grammage[r].weight} <span class="recipe-name">${r}</span></li>`;
      }
    }

    return html;
  }

  @autobind
  update_grammage() {
    const grammage: {
        full: Grammage,
        half: Grammage
      } = {
        full: null,
        half: null
      },
      halfPension: Variant = this.variants.filter(v =>
        v.option1 === this.current_recipes() && v.option2 === 'H' && v.option3 === this.current_cycle())[0],
      fullPension: Variant = this.variants.filter(v =>
        v.option1 === this.current_recipes() && v.option2 === 'V' && v.option3 === this.current_cycle())[0];

    grammage.full = this.variant_metafields(fullPension);

    if (typeof halfPension !== 'undefined') {
      grammage.half = this.variant_metafields(halfPension);
    }
  }

  @autobind
  update_plan() {
    const pricingBar = document.querySelector('.pricing-bar');
    if (!pricingBar) return;
  
    const recipes = this.current_recipes();
    const allRecipes = document.querySelectorAll('[data-recipe]');
    if (!allRecipes.length) return;
  
    Array.prototype.forEach.call(allRecipes, function (recipeEl) {
      const isSelected = recipes.indexOf(recipeEl.dataset.recipe) !== -1;
      recipeEl.classList.toggle('d-none', !isSelected);
      recipeEl.dataset.selected = isSelected;
    });
  
    const selectedRecipes = Array.prototype.filter.call(allRecipes, function (recipeEl) {
      return recipeEl.dataset.selected === "true" && recipeEl.closest('.pricing-bar-recipes-indicators');
    }).map(function (recipeEl) {
      return recipeEl.dataset.title;
    });

    const selectedRecipesUnique = selectedRecipes.filter((item, index, self) => 
      index === self.findIndex(t => t === item)
    );
    
    const pricingBarRecipeTitles = document.querySelectorAll('.pricing-bar-recipe-title');
    
    Array.prototype.forEach.call(pricingBarRecipeTitles, function (pricingBarRecipeTitle) {
      let textContent = '';

      if (selectedRecipesUnique.length === 3) {
        textContent = window.I18n.all_recipes
      } else if (selectedRecipesUnique.length === 1) {
        textContent = selectedRecipesUnique[0]
      } else if (selectedRecipesUnique.length === 0) {
        textContent = window.I18n.no_selected_recipes
      } else {
        textContent = window.I18n.more_recipes
      }

      pricingBarRecipeTitle.textContent = textContent;
    })
   
  
    const more = pricingBar.querySelector('.pricing-bar-more');
    more.classList.toggle('d-none', selectedRecipesUnique.length === 0);
  
    this.update_pension();
    this.update_cycle();
  
    const elementsToToggle: string[] = [
      '.pricing-bar-wrapper',
      '.nach-info',
      '.dein-info',
      '.dein-package',
      '.nach-package'
    ];
  
    elementsToToggle.forEach(selector => {
      const element = document.querySelector(selector) as HTMLElement;
      if (element) {
        element.classList.toggle('d-none', selectedRecipes.length === 0);
      }
    });
  
    const buttonsToDisable = document.querySelectorAll('.btn-add-to-cart, .recommendation-submit-new');
      buttonsToDisable.forEach(button => {
        const isDisabled = selectedRecipes.length === 0;
      button.classList.toggle('disabled', isDisabled);
  });
}
  

  @autobind
  update_pension() {
    const currentPension = this.current_pension();

    const allPensions = document.querySelectorAll('[data-pension]');
    if (!allPensions.length) return;

    Array.prototype.forEach.call(allPensions, function (pensionEl) {
      pensionEl.textContent = currentPension === 'H' ? window.I18n.pension.half : window.I18n.pension.full;
    })
  }

  @autobind
  update_cycle() {
    const currentCycle = this.current_cycle();

    const allCycles = document.querySelectorAll('[data-cycle]');
    if (!allCycles.length) return;

    Array.prototype.forEach.call(allCycles, function (cycleEl) {
      cycleEl.textContent = currentCycle;
    })
  }

  @autobind
  update_variant() {
    const variant = this.current_variant()

    const metafields = this.variant_metafields_new(variant);

    const allRecipes = document.querySelectorAll('.after-recipe[data-recipe]');
    if (!allRecipes.length) return;

    const weights = Object.keys(metafields).map(key => metafields[key].weight);

    if (weights.length) {
      const minWeight = Math.min(...weights);
      const maxWeight = Math.max(...weights);

      const dataGrammageMin = document.querySelector('[data-grammage-min]');
      const dataGrammageMax = document.querySelector('[data-grammage-max]');

      if (dataGrammageMin) dataGrammageMin.textContent = `${minWeight}g`;
      if (dataGrammageMax) dataGrammageMax.textContent = `${maxWeight}g`;
    }

    const amounts = Object.keys(metafields).map(key => metafields[key].amount);

    if (amounts.length) { 
      const dataQuantityTotal = document.querySelector('[data-quantity-total]');
  
      const totalAmount = amounts.reduce((acc, curr) => acc + curr, 0);
  
      if (dataQuantityTotal) dataQuantityTotal.textContent = `${totalAmount}`;
    }

    Array.prototype.forEach.call(allRecipes, function (recipeEl) {
      if (recipeEl.dataset.selected) {
        const recipe = metafields[recipeEl.dataset.recipe.toLowerCase()];

        const dataQuantity = recipeEl.querySelector('[data-quantity]');
        const dataGrammage = recipeEl.querySelector('[data-grammage]');

        if(dataQuantity) dataQuantity.textContent = recipe.amount;
        if(dataGrammage) dataGrammage.textContent = recipe.weight;
      }
    });
  }
  
  @autobind
  update_pension_view() {
    const currentPension = this.current_pension();
    console.log("Selected pension:", currentPension);
  
    const pensionHeadlineFull = document.querySelector('.pension-headline-full') as HTMLElement;
    const pensionHeadlineHalf = document.querySelector('.pension-headline-half') as HTMLElement;
  
    const fullPensionLi = document.querySelector('.pension-full') as HTMLElement;
    const halfPensionLi = document.querySelector('.pension-half') as HTMLElement;
  
    if (!fullPensionLi || !halfPensionLi) return;

    if (currentPension === "V") {
      fullPensionLi.classList.remove('d-none');
      halfPensionLi.classList.add('d-none');
      pensionHeadlineFull.classList.add('active')
      pensionHeadlineHalf.classList.remove('active')
    } else {
      fullPensionLi.classList.add('d-none');
      halfPensionLi.classList.remove('d-none');
      pensionHeadlineFull.classList.remove('active')
      pensionHeadlineHalf.classList.add('active')
    }
  }
  
  

  @autobind
  update_test_package() {
    const currentPension = this.current_pension();
    const variants = this.current_test_packages();
  
    const variant = variants.find(v => v.option2 === currentPension);
    if (!variant) return;
  
    const variantFull = variants.find(v => v.option2 === 'V');
    const variantHalf = variants.find(v => v.option2 === 'H');
  
    const testPackageVPriceEl = document.querySelector('[data-tp-v-price]') as HTMLElement | null;
    const testPackageHPriceEl = document.querySelector('[data-tp-h-price]') as HTMLElement | null;
  
    const testPackageVPriceDiscountedEl = document.querySelector('[data-tp-v-price-discounted]') as HTMLElement | null;
    const testPackageHPriceDiscounted = document.querySelector('[data-tp-h-price-discounted]') as HTMLElement | null;
  
    if (testPackageHPriceEl && testPackageVPriceEl) {
      const priceV = variantFull?.test_package.find(f => f.key === 'per_day')?.value ?? 0;
      const priceH = variantHalf?.test_package.find(f => f.key === 'per_day')?.value ?? 0;
  
      testPackageVPriceEl.textContent = priceV.toFixed(2).replace('.', ',');
      testPackageHPriceEl.textContent = priceH.toFixed(2).replace('.', ',');
    }
  
    if (testPackageVPriceDiscountedEl && testPackageHPriceDiscounted) {
      const discountDataElement = document.getElementById("discount-data") as HTMLElement | null;
      const discountPercentage = discountDataElement ? parseFloat(discountDataElement.dataset.discountPercentage || '1') : 1;
  
      const priceV = variantFull?.test_package.find(f => f.key === 'per_day')?.value ?? 0;
      const priceH = variantHalf?.test_package.find(f => f.key === 'per_day')?.value ?? 0;
  
      testPackageVPriceDiscountedEl.textContent = (priceV * discountPercentage).toFixed(2).replace('.', ',');
      testPackageHPriceDiscounted.textContent = (priceH * discountPercentage).toFixed(2).replace('.', ',');
    }
  
    const metafields = this.variant_metafields_new(variant);
    const allRecipes = document.querySelectorAll('.test-recipe[data-recipe]');
    if (!allRecipes.length) return;
  
    const weights = Object.keys(metafields).map(key => metafields[key].weight);
    if (weights.length) {
      const minWeight = Math.min(...weights);
      const maxWeight = Math.max(...weights);
  
      const dataGrammageMin = document.querySelectorAll('[data-tp-grammage-min]') as NodeListOf<HTMLElement>;
      const dataGrammageMax = document.querySelector('[data-tp-grammage-max]') as HTMLElement | null;
  
      dataGrammageMin.forEach(el => {
        el.textContent = `${minWeight}g-`;
        el.style.display = minWeight === 0 ? 'none' : '';
      });
  
      if (dataGrammageMax) {
        dataGrammageMax.textContent = `${maxWeight}`;
      }
    }
  
    const amounts = Object.keys(metafields).map(key => metafields[key].amount);
    if (amounts.length) { 
      const dataQuantityTotal = document.querySelector('[data-tp-quantity-total]') as HTMLElement | null;
      const totalAmount = amounts.reduce((acc, curr) => acc + curr, 0);
  
      if (dataQuantityTotal) {
        dataQuantityTotal.textContent = `${totalAmount}`;
      }
    }
  
    allRecipes.forEach(recipeEl => {
      if (recipeEl instanceof HTMLElement && recipeEl.dataset.selected) {
        const recipeKey = recipeEl.dataset.recipe?.toLowerCase();
        const recipe = recipeKey ? metafields[recipeKey] : null;
  
        if (recipe) {
          const dataQuantity = recipeEl.querySelector('[data-quantity]') as HTMLElement | null;
          const dataGrammage = recipeEl.querySelector('[data-grammage]') as HTMLElement | null;
          const dataPercentage = recipeEl.querySelector('[data-percentage]') as HTMLElement | null;
  
          if (dataQuantity) dataQuantity.textContent = `${recipe.amount}`;
          if (dataGrammage) dataGrammage.textContent = `${recipe.weight}`;
          if (dataPercentage) dataPercentage.textContent = `${recipe.percentage}`;
        }
      }
    });
  }
  

  @autobind
  current_variant() {
    const variant = this.variants.find(v => 
        v.option1 === this.current_recipes() &&
        v.option2 === this.current_pension() &&
        v.option3 === this.current_cycle()
      );

    return variant;
  }

  @autobind
  current_test_package() {
    const testPackageEl: HTMLElement = document.querySelector('[data-test-package]');
    if (!testPackageEl) return null;

    const variant = this.variants.find(v => 
        v.option1 === this.current_recipes() &&
        v.option2 === this.current_pension() &&
        v.option3 === testPackageEl?.dataset.testPackage
      );

    return variant;
  }
  @autobind
  current_test_packages() {
    const testPackageEl: HTMLElement = document.querySelector('[data-test-package]');
    if (!testPackageEl) return null;

    const variants = this.variants.filter(v => 
        v.option1 === this.current_recipes() &&
        v.option3 === this.current_cycle()
      );

    return variants;
  }

  @autobind
  current_recipes() {
    return Array.from(document.querySelectorAll('input[name="variant[recipe][]"]:checked'))
      .map(e => (e as HTMLInputElement).value).join('');
  }

  @autobind
  current_cycle() {
    return document.querySelector('.cycle.item.active').querySelector('input').value
  }

  @autobind
  current_pension() {
    return document.querySelector('.pension.item.active').querySelector('input').value
  }

  @autobind
  submit_form() {
    const submitBtn: HTMLButtonElement[] = Array.from(
      document.querySelectorAll('.recommendation-submit')
    ) as HTMLButtonElement[];
    if (!submitBtn.length) return;

    const form: HTMLFormElement = document.querySelector('#recommendation-form');
    if (!form) return;

    Array.prototype.forEach.call(submitBtn, (button: HTMLButtonElement) => 
    button.addEventListener('click', function () {
      form.submit();
    }))
  }



}